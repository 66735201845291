import * as React from 'react'
import { graphql } from 'gatsby'
import { OneColSection } from '../components/OneColSection'

export const TextBlock = ({ slice }) => {
  // console.log('TextBlock data', slice)

  return (
    <>
      <OneColSection content={slice.primary.text_block.raw} marginTop={slice.primary.margin_top} textAlign={slice.primary.text_align_center} />
    </>
  )
}

export const query = graphql`
  fragment PrismicPageDataBodyTextBlock on PrismicPageDataBodyTextBlock {
    slice_type
    primary {
      text_align_center
      text_block {
        raw
      }
    }
  }
  fragment PrismicHomepageDataBodyTextBlock on PrismicHomepageDataBodyTextBlock {
    slice_type
    primary {
      text_align_center
      margin_top
      text_block {
        raw
      }
    }
  }
`