import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

const StyledFormControl = styled(Form.Control)`
    /* .form-control::placeholder {
            color: red !important;
            opacity: 1 !important;
    } */

    background-color: var(--page-bg-color);
    border-top:  none;
    border-left:  none;
    border-right:  none;
    border-bottom: var(--header-bg-color) 1px solid;
    border-radius: 0;
    color: var(--page-text-color) !important;
    width: 100%;

        &:focus{
            box-shadow: none;
            outline: none;
            background-color: var(--page-bg-color);
            border-bottom: var(--header-bg-color) 2px solid;
        }
`
const StyledFormLabel = styled(Form.Label)`
    color: var(--page-text-color);
    margin-top: 1rem !important;
`

export { StyledFormControl, StyledFormLabel }