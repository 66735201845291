import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'

export const Hero = ({ slice }) => {
  console.log('Hero data', slice)
  // const mainImg = slice.primary.background_image.localFile.childImageSharp.gatsbyImageData
  const thumbImgs = slice.primary.background_image.thumbnails

const titleText = slice.primary.hero_title.text


const titleTextDu = titleText.substring(0,2)  //Extrahiere die ersten beiden Zeichen
const titleTextSelbst = titleText.substring(2) // Extrahiere den Rest des Strings

console.log('titleTextDu', titleTextDu)
console.log('titleTextSelbst', titleTextSelbst)

  return (
    <PageCover
      className="d-flex justify-content-center align-items-center"
      role="img"
      aria-label={slice.primary.background_image.alt}
    >
      <div className="cover-text-box">
        <div className="cover-text">
          {/* <RichText render={slice.primary.hero_title.raw || []} /> */}
          {/* {slice.primary.hero_title.html} */}
          <h1><span>{titleTextDu}</span>{titleTextSelbst}</h1>
          <RichText render={slice.primary.hero_content.raw || []} />
        </div>
      </div>
    </PageCover>
  )
}

const PageCover = styled.section`
  // Extra small devices (portrait phones, less than 576px)
  // No media query for xs since this is the default in Bootstrap

  background-color: var(--header-bg-color);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* ohne Ränder */
  margin: 0 -28px 0 -28px !important;
  height: 101vh !important;
  padding-top: 11rem !important;

  .cover-text-box {
    background-color: ${(props) => props.bannerBgColor};
    color: #a9a9da;
  }

  .cover-text {
    text-align: center;
    /*     margin-left: 2rem;
    margin-right: 2rem; */
    /* font-family: 'Mochiy Pop P One'; */
    font-family: 'Michroma';
    /* font-family: 'Gotu'; */
    padding: 0;
    color: #eee8aa;
    /* color: var(--header-color); */
  }

  .cover-text h1 {
    font-size: 2.6rem !important;
    line-height: 2;
    margin-top: -10rem;
    color: #eee8aa;
    font-weight: bold;
    letter-spacing: 0.1em;
  }

  .cover-text h1 span {
    font-weight: 100; 
  }

  .cover-text h2 {
    font-size: 1rem;
    margin-top: 2rem;
    line-height: 1.5;
  }

  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
    background-image: url(${(props) => props.mobileLsImg});

    .cover-text-box {
      /*       min-height: 35vh;
      min-width: 50vw;
      position: absolute;
      top: 20vh;
      left: 8vw;
      border-radius: 40px;
      padding-top: 2rem;
      padding-bottom: 2rem;
      color: #dfe2e8; */
    }

    h1.cover-text {
      font-size: 1.3rem;
    }

    .cover-text h2 {
      font-size: 1.2rem;
      margin-top: 0;
      padding-top: 1rem;
    }

    .cover-text {
      /* width: 90%; */
    }
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
    background-image: url(${(props) => props.tabletImg});

    .cover-text-box {
      /*       min-width: 40vw;
      position: absolute;
      top: 25vh;
      left: 10vw; */
    }

    .cover-text h1 {
      font-size: 2.3rem;
    }

    .cover-text h2 {
      font-size: 1.6rem;
    }
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    background-image: url(${(props) => props.desktopImg});
    height: calc(100vh - 55px); // minus hight of navbar

    .cover-text-box {
      /*       min-width: 40vw;
      position: absolute;
      top: 25vh;
      left: 10vw; */
    }

    .cover-text h1 {
      font-size: 2.3rem;
    }

    .cover-text h2 {
      font-size: 1.6rem;
    }

    .cover-text {
      /* width: 8%; */
    }
  }

  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }
`

export const query = graphql`
  fragment PrismicHomepageDataBodyHero on PrismicHomepageDataBodyHero {
    slice_type
    primary {
      banner_bg_color
      banner_bg_transparency
      cta_target {
        uid
      }
      hereo_cta_button_label
      hero_content {
        html
        raw
        text
      }
      hero_title {
        text
        html
        raw
      }
      background_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 60)
          }
        }
        thumbnails {
          desktop {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 60)
              }
            }
          }
          mobile_ls {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 60)
              }
            }
          }
          tablet {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 60)
              }
            }
          }
        }
      }
    }
  }
`
